.contact-wrapper {
  background-image: url('../../assets/images/earth.jpg');
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.contact-form {
  padding: 80px;
  border-radius: 5px;
  width: 800px;
  box-sizing: border-box;
}

.contact-form input,
.contact-form textarea {
  background-color: transparent;
}

.contact-form .form-control:focus {
  background-color: transparent !important;
}

.contact-form .input-style {
  display: block;
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #08FDD8;
  border: 1px solid rgba(24, 42, 59, 0.7);
  border-radius: 5px;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  outline: none;
  box-shadow: 0 0 1px 1px hsla(171, 98%, 51%, 0.2);
  background: rgba(8, 253, 216, 0.1);
  backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(30px);
}

.contact-form .input-style:focus {
  border: 1px solid #08FDD8;
  box-shadow: 0 0 10px 10px hsla(171, 98%, 51%, 0.2);
}

.contact-form .input-style-filled {
  display: block;
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #08FDD8;
  background-color: transparent;
  border-radius: 5px;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  outline: none;
  border: 1px solid #08FDD8;
  box-shadow: 0 0 10px 10px hsla(171, 98%, 51%, 0.2);
}

.contact-form .input-style-invalid {
  display: block;
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #8e3ff2;
  background-color: transparent;
  border-radius: 5px;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  outline: none;
  border: 1px solid #8e3ff2;
  box-shadow: 0 0 10px 10px hsla(11, 80%, 60%, 0.2);
}

.textarea-wrapper,
.contact-inputs-wrapper {
  padding-top: 30px;
}

.textarea-wrapper textarea {
  height: 350px;
}

.submit-btn {
  background-color: rgba(24, 42, 59, 0.7);
  border: 2px double #08FDD8;
  border-radius: 5px;
  padding: 12px 40px;
  color: white;
  transition-duration: 0.4s;
  position: relative;
  overflow: hidden;
  outline: none;
  margin-top: 50px;
}

.submit-btn:after {
  content: "";
  background: hsla(171, 98%, 51%, 0.5);
  ;
  display: block;
  position: absolute;
  padding-top: 300%;
  padding-left: 350%;
  margin-left: -40px !important;
  margin-top: -120%;
  opacity: 0;
  transition: all 0.8s;
  border-radius: 20%;
}

.submit-btn:active:after {
  padding: 0;
  margin: 0;
  opacity: 1;
  transition: 0s
}

.submit-btn:active,
.submit-btn:focus {
  outline: none !important;
}

.submit-btn:focus {
  box-shadow: none;
}

.submit-btn:hover {
  background-color: rgb(10, 23, 36);
  box-shadow: 0 0 10px 10px hsla(171, 98%, 51%, 0.2);
  color: white;
}

.input-label,
.input-label-invalid {
  width: 100%;
  position: relative;
  display: inline-block;
}

.input-label span {
  padding: 10px;
  pointer-events: none;
  position: absolute;
  left: 0;
  top: 0;
  transition: 0.2s;
  transition-timing-function: ease;
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  opacity: 1;
  color: #08FDD8;
}

.input-label-invalid span {
  padding: 10px;
  pointer-events: none;
  position: absolute;
  left: 0;
  top: 0;
  transition: 0.2s;
  transition-timing-function: ease;
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  opacity: 1;
  color: #8e3ff2;
}

input:focus+span,
input:not(:placeholder-shown)+span,
textarea:focus+span,
textarea:not(:placeholder-shown)+span {
  opacity: 1;
  transform: scale(0.75) translateY(-100%) translateX(-20px);
}

.space-shuttle {
  width: 200px;
  height: 200px;
  position: absolute;
  bottom: 0px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url('../../assets/images/space_shuttle.png');
  opacity: 0;
  z-index: -1;
}

.space-shuttle-launched {
  width: 200px;
  height: 200px;
  position: absolute;
  bottom: 0px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url('../../assets/images/space_shuttle.png');
  animation: move 2s ease-in forwards;
  opacity: 1;
  z-index: 999;
}

@keyframes move {
  0% {
    bottom: 0px;
  }

  100% {
    bottom: 2300px;
  }
}

@media only screen and (max-width: 1300px) {
  .textarea-wrapper textarea {
    height: 230px;
  }

}


@media only screen and (max-width: 800px) {
  .contact-form {
    width: 100%;
    padding: 35px;
  }
}

@media only screen and (max-width: 767px) {

  .input-label.input-name,
  .input-label-invalid.input-name {
    margin-bottom: 35px;
  }

  .contact-wrapper {
    height: 700px;
  }

  .textarea-wrapper textarea {
    height: 150px;
  }
}