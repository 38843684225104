.projects-wrapper {
  background-color: rgba(1, 1, 41, 1);
  background-image: url('../../assets/images/gradient6.jpg');
  background-size: cover;
  background-attachment: fixed;
}

.projects-title-section {
  padding: 100px 0px;
  text-align: left;
}

.project-title {
  color: #08FDD8;
  text-decoration: none;
}

.project-info {
  color: white;
  font-size: 1rem;
}

.projects-grid {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  padding-bottom: 100px;
  column-gap: 30px;
  row-gap: 30px;
}

.project {
  height: 300px;
  background: rgba(255, 255, 255, 0.19);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(2.5px);
  -webkit-backdrop-filter: blur(2.5px);
  border: 1px solid rgba(255, 255, 255, 0.47);
}

.project-inside {
  width: 100%;
  height: 100%;
  background-color: rgba(1, 1, 41, 1);
  border-radius: 16px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-repeat: no-repeat;
  background-size: cover !important;
  background-position: center !important;
  transition: all .5s;
}

.project-inside.trackado {
  background: linear-gradient(to right, rgba(1, 1, 41, 0.7), rgba(1, 1, 41, 0.8)), url('../../assets/images/contract.jpg');
}

.project-inside.plieger {
  background: linear-gradient(to right, rgba(1, 1, 41, 0.7), rgba(1, 1, 41, 0.8)), url('../../assets/images/commerce.jpg');
}

.project-inside.plieger-mobile {
  background: linear-gradient(to right, rgba(1, 1, 41, 0.7), rgba(1, 1, 41, 0.8)), url('../../assets/images/commerce_mobile.jpg');
}

.project-inside.field-eagle {
  background: linear-gradient(to right, rgba(1, 1, 41, 0.7), rgba(1, 1, 41, 0.8)), url('../../assets/images/mining.jpg');
}

.project-inside.field-eagle-mobile {
  background: linear-gradient(to right, rgba(1, 1, 41, 0.7), rgba(1, 1, 41, 0.8)), url('../../assets/images/construction.jpg');
}

.project-inside.menusano {
  background: linear-gradient(to right, rgba(1, 1, 41, 0.7), rgba(1, 1, 41, 0.8)), url('../../assets/images/nutrition.jpg');
}

.project-inside.tiktak {
  background: linear-gradient(to right, rgba(1, 1, 41, 0.7), rgba(1, 1, 41, 0.8)), url('../../assets/images/healthcare.jpg');
}

.project-inside.associations {
  background: linear-gradient(to right, rgba(1, 1, 41, 0.7), rgba(1, 1, 41, 0.8)), url('../../assets/images/mobile.jpg');
}

.project:hover .project-inside {
  background-color: rgba(1, 1, 41, 0.85);
}

.project:hover .project-title {
  color: white;
  text-decoration: none;
}

@property --angle {
  syntax: "<angle>";
  initial-value: 0deg;
  inherits: false;
}

.moving-border {
  height: 300px;
  position: relative;
  padding: 2px;
  border-radius: 16px;
}

.moving-border::before,
.moving-border::after {
  content: "";
  position: absolute;
  inset: 0;
  z-index: -1;
  border-radius: 16px;
  background: linear-gradient(var(--angle),
      #010129, #C3F2FF, #08FDD8);
  animation: rotate 8s linear infinite;
}

.moving-border::after {
  filter: blur(10px);
}

@keyframes rotate {
  0% {
    --angle: 0deg;
  }

  100% {
    --angle: 360deg;
  }
}

/* MEDIA QUERIES */


@media only screen and (max-width: 1440px) {
  .projects-grid {
    column-gap: 20px;
    row-gap: 20px;
  }
}

@media only screen and (max-width: 1300px) {
  .projects-title-section {
    margin-left: 8.333333%;
    margin-right: 8.3333%;
    max-width: 100% !important;
    flex: 100% !important;
  }

  .projects-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (max-width: 767px) {
  .projects-wrapper {
    background-attachment: unset;
    background-size: cover;
    background-image: url('../../assets/images/gradient5.jpg');
  }

  div.projects-grid {
    padding-left: 50px;
    padding-right: 50px;
  }
}

@media only screen and (max-width: 600px) {
  .projects-grid {
    grid-template-columns: repeat(1, 1fr);
  }

  .project,
  .moving-border {
    height: 200px;
  }
}

@media only screen and (max-width: 450px) {
  .project-info {
    padding: 10px;
  }
}