.skills-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.skills-wrapper {
  height: 80vh;
  perspective: 40em;
  overflow: hidden;
  background-attachment: fixed;
  background-image: url('../../assets/images/black.jpg');
  background-size: cover;
}

.skills-wrapper * {
  position: absolute;
  top: 55%;
  left: 50%;
  transform-style: preserve-3d;
}

.skills-title {
  position: absolute;
  margin-left: 8.333333%;
  margin-right: 8.333333%;
  margin-top: 100px;
  z-index: 999;
  text-align: left;
  max-width: 41.666667%;
  padding: 0px 15px;
}

.holster {
  margin-left: 20%;
}

$ring-radius: 225px;
$ring-count: 24;
$ring-start-angle: 70;
$ring-length-angle: 100deg;
$ring-angle: 360deg / $ring-count;

.ring {
  height: $ring-radius * 2;
  width: $ring-radius * 2;
  border-radius: 50%;
  margin-top: -$ring-radius;
  margin-left: -$ring-radius;
  backface-visibility: visible;
  user-select: none;
}

.skills-overlay {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(1, 1, 41, 0.2);
  z-index: 99;
  position: absolute;
}

.tech-name {
  color: white;
  font-family: 'Roboto', sans-serif;
  letter-spacing: 4px;
  text-shadow: 10px 5px 15px white;
}

.tech1,
.tech2,
.tech3,
.tech4,
.tech5 {
  transform: scale(-1, -1);
}

.tech1 {
  margin-top: 30px;
}

.tech2 {
  margin-top: 60px;
}

.tech3 {
  margin-top: 90px;
}

.tech4 {
  margin-top: 120px;
}

.tech5 {
  margin-top: 150px;
}

.tech6 {
  margin-top: -150px;
  transform: rotateX(3.142rad);
}

.tech7 {
  margin-top: -120px;
  transform: rotateX(3.142rad);
}

.tech8 {
  margin-top: -90px;
  transform: rotateX(3.142rad);
}

.tech9 {
  margin-top: -60px;
  transform: rotateX(3.142rad);
}

.tech10 {
  margin-top: -30px;
  transform: rotateX(3.142rad);
}

@for $i from 0 through $ring-count {
  .ring:nth-child(#{$i + 1}) {
    transform: rotateY($ring-angle * $i);
  }
}

.ball {
  transform-origin: 50% 50%;
  animation: rott 20s infinite linear;
}

@keyframes rott {
  to {
    transform: rotatey(360deg) rotatex(360deg);
  }
}

@media only screen and (max-width: 1300px) {
  .skills-title {
    margin-left: 8.333333%;
    margin-right: 8.3333%;
    max-width: 100% !important;
    flex: 100% !important;
  }

  .holster {
    margin-top: 100px;
    margin-left: 0px;
  }

  .skills-wrapper {
    height: 135vh;
  }
}

@media only screen and (max-width: 767px) {
  .holster {
    margin-top: 130px;
  }

  .skills-wrapper {
    height: 1100px;
    background-attachment: unset;
    background-size: contain;
    background-image: url('../../assets/images/black.jpg');
  }
}

@media only screen and (max-width: 450px) {
  .holster {
    margin-top: 150px;
  }
}

@media only screen and (max-width: 400px) {
  .holster {
    margin-top: 180px;
  }
}