.canvas-wrapper {
  height: 100vh;
  padding-left: -15px !important;
  padding-right: -15px !important;
}

.canvas {
  height: 100vh;
  width: 100%;
  background-size: cover;
  animation: fadeIn 2s ease-in-out forwards;
}

.draw {
  stroke: rgba(255, 255, 255, 0.4);
  stroke-dasharray: 2000;
  stroke-dashoffset: 2000;
  animation-duration: 6.0s;
  animation-fill-mode: forwards;
  animation-name: draw;
}

.typist {
  color: white;
  font-size: 30px;
  font-family: 'Roboto', sans-serif;
  word-spacing: 2px;
  letter-spacing: 4px;
  margin-top: -170px;
  cursor: auto;
  user-select: none;
  width: 100%;
}

.typist-web {
  display: block;
}

.typist-mobile {
  display: none;
}

.typist-text {
  text-shadow: "1px 1px black" !important;
}

.svg-container {
  position: absolute;
  top: calc(50% - 105px);
  left: calc(50% - 205px);
}

/* ANIMATIONS */
@keyframes draw {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* MEDIA QUERIES */

@media only screen and (max-width: 424px) {
  svg {
    display: none;
  }

  .svg-container {
    top: calc(50% + 100px);
    left: calc(50% - 140px);
  }

  .typist-web {
    display: none;
  }

  .typist-mobile {
    display: block;
  }
}

@media only screen and (max-width: 374px) {
  .typist {
    letter-spacing: 4px;
  }
}