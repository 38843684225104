.navigation-wrapper {
  position: fixed;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 10px;
  z-index: 9999;
}

.nav-list {
  list-style: none;
  display: inline;
  color: white;
}

.nav-list-mobile {
  list-style: none;
  display: none;
  color: white;
}

.nav-list-item {
  display: inline;
  cursor: pointer;
  margin: 10px;
  position: relative;
  padding-bottom: 3px;
  letter-spacing: 4px;
}

.nav-list-item a,
.nav-list-item a:hover,
.nav-list-item a:active,
.nav-list-item a:focus {
  color: #08FDD8;
  text-decoration: none;
}

.nav-list-item::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  border-bottom: 2px solid #08FDD8;
  transition: width 0.2s ease;
}

.nav-list-item:hover::after {
  width: 100%;
}

.nav-icon-wrapper {
  position: fixed;
  top: -20px;
  right: 20px;
  display: none;
  z-index: 99999;
}

#nav-icon {
  width: 40px;
  height: 45px;
  position: relative;
  margin: 50px auto;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
}

#nav-icon span {
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  background: #08FDD8;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
}

#nav-icon span:nth-child(1) {
  top: 0px;
}

#nav-icon span:nth-child(2) {
  top: 18px;
}

#nav-icon span:nth-child(3) {
  top: 36px;
}

#nav-icon.open span:nth-child(1) {
  top: 18px;
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg);
}

#nav-icon.open span:nth-child(2) {
  opacity: 0;
  left: -60px;
}

#nav-icon.open span:nth-child(3) {
  top: 18px;
  -webkit-transform: rotate(-135deg);
  -moz-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

.open-nav {
  display: flex !important;
  transform: translateX(0)
}

.closed-nav {
  display: flex !important;
  transform: translateX(-100%)
}


/* MEDIA QUERIES */

@media only screen and (max-width: 768px) {
  .nav-list-item {
    display: block;
    margin: 30px;
  }

  .nav-list {
    display: none;
  }

  .nav-list-mobile {
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0px;
    position: fixed;
    width: 100%;
    height: 100vh;
    background-color: rgb(1, 1, 41);
    z-index: 999;
    top: 0;
    right: 0;
    transition: transform 0.4s ease-out;
  }

  .nav-icon-wrapper {
    display: block;
  }
}

@media only screen and (min-width: 769px) {
  .nav-list-mobile {
    display: none !important;
  }

}