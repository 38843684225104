.about-wrapper {
  height: 700px;
  background-attachment: fixed;
  background-size: cover;
  background-image: url('../../assets/images/black.jpg');
  background-repeat: no-repeat;
  position: relative
}

.about-overlay {
  height: 100%;
  width: 100%;
}

.about-content {
  height: 100%;
  width: 100%;
  background: linear-gradient(to bottom, rgba(1, 1, 41, 1), rgba(1, 1, 41, 1), rgba(1, 1, 41, 0.5), rgba(1, 1, 41, 0.2));
  padding: 100px 0px;
  position: relative;
}

.about-left-section {
  text-align: left;
}

.fade-in-section {
  opacity: 0;
  transform: translateY(20vh);
  visibility: hidden;
  will-change: opacity, transform, visibility;
}

.fade-in-section.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}

ul.social-list {
  transform: translate(0%, 0%);
  list-style-type: none;
  padding: 0;

  li {
    display: inline-block;
    margin: 0px 40px;
    position: relative;
    transition: 0.5s;

    a {
      color: white;
      height: 60px;
      width: 60px;
      display: inline-block;

      i.fa {
        display: inline-block;
        height: 60px;
        width: 60px;
        text-align: center;
        line-height: 30px;
        padding: 10px;
        border-radius: 40px;
        color: rgb(24, 42, 59);
        position: relative;
        transition: all ease 0.2s;

        &::after {
          content: "";
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto;
          height: 100%;
          width: 100%;
          z-index: -1;
          border-radius: 40px;
          background-color: rgb(1, 1, 41);
          border: 1px solid #08FDD8;
        }

        &:hover {
          color: rgb(24, 42, 59);
          font-size: 18px;

          &::after {
            animation: animate ease 0.6s forwards;
          }
        }
      }

      i.fa-facebook:hover {
        &::after {
          background-color: #08FDD8;
        }
      }

      i.fa-linkedIn:hover {
        &::after {
          background-color: #08FDD8;
        }
      }

      i.fa-github:hover {
        &::after {
          background-color: #08FDD8;
        }
      }

    }
  }
}

ul.social-list li a:hover .social {
  color: rgb(24, 42, 59);
}

.social-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.social-linked-in {
  position: absolute;
  left: calc(50% - 7px);
  top: calc(50% - 10px);
}

.social-facebook {
  position: absolute;
  left: calc(50% - 5px);
  top: calc(50% - 10px);

}

.social-github {
  position: absolute;
  left: calc(50% - 7px);
  top: calc(50% - 10px);

}

.text-link {
  position: relative;
  cursor: pointer;
  padding-bottom: 3px;
}

.text-link a,
.text-link a:hover,
.text-link a:active,
.text-link a:focus {
  color: #08FDD8;
  text-decoration: none;
}

.text-link::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  border-bottom: 2px solid #08FDD8;
  transition: width 0.2s ease;
}

.text-link:hover::after {
  width: 100%;
}

// ANIMATIONS

@keyframes animate {

  0%,
  20% {
    transform: scale(1);
    border-radius: 40px;
    background-color: #08FDD8;
  }

  30% {
    transform: scale(1.2);
  }

  40% {
    transform: scale(1.1);
  }

  50% {
    transform: scale(1);
    border-radius: 10px;
  }

  60%,
  100% {
    transform: scale(1);
    border-radius: 5px;
  }
}


// MEDIA QUERIES

@media only screen and (max-width: 1300px) {
  .about-left-section {
    margin-left: 8.333333%;
    margin-right: 8.3333%;
    margin-bottom: 50px;
    max-width: 100% !important;
    flex: 100% !important;
  }

  .social-wrapper {
    max-width: 100% !important;
    flex: 100% !important;
  }
}

@media only screen and (max-width: 1100px) {
  ul.social-list li {
    margin: 0px 18px
  }
}

@media only screen and (max-width: 767px) {
  ul.social-list li {
    margin: 0px 40px
  }

  .about-left-section {
    margin-bottom: 80px;
  }

  .about-wrapper {
    height: 100vh;
    background-attachment: unset;
    background-size: cover;
    background-image: url('../../assets/images/gradient5.jpg');
  }
}

@media only screen and (max-width: 767px) {
  .about-wrapper {
    height: 900px;
  }
}

@media only screen and (max-width: 450px) {
  ul.social-list li {
    margin: 0px 15px
  }
}