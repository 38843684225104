.scroll-top {
  position: fixed;
  bottom: 10px;
  right: 10px;
  width: 40px;
  height: 50px;
  transition: 0.5s;
  z-index: 99999;
}

.scroll-top-vissible {
  opacity: 1;
}

.scroll-top-hidden {
  opacity: 0;
}

.shuttle-icon {
  transform: rotateY(0deg) rotate(-90deg);
  color: white;
  font-size: 30px;
  cursor: pointer;
}

.shuttle-wrapper {
  position: relative;
}

.exhaust-flame {
  position: absolute;
  top: 70%;
  width: 12px;
  background: linear-gradient(to bottom, transparent 10%, white 100%);
  height: 140px;
  left: calc(50% - 6px);
  animation: exhaust 0.2s infinite;
}

/* EXHAUST ANIMATION */
@keyframes exhaust {
  0% {
    background: linear-gradient(to bottom, transparent 10%, white 100%);
  }

  50% {
    background: linear-gradient(to bottom, transparent 8%, white 100%);
  }

  75% {
    background: linear-gradient(to bottom, transparent 12%, white 100%);
  }
}