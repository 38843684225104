.App {
  text-align: center;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif !important;
}

main {
  background-color: rgba(1, 1, 41, 1);
}

h2 {
  font-size: 2.5rem !important;
}

body a:hover {
  text-decoration: none;
}


.turquoise {
  color: #08FDD8
}

.title-wrapper {
  position: relative;
  margin-bottom: 40px;
}

.section-title {
  color: #08FDD8;
  letter-spacing: 4px;
  font-family: 'Roboto', sans-serif;
  padding-bottom: 10px;
}

.section-title::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 150px;
  border-bottom: 2px solid #08FDD8;
  transition: width 0.2s ease;
}

.section-text {
  color: white;
  letter-spacing: 2px;
  font-family: 'Roboto', sans-serif;
  word-spacing: 4px;
  line-height: 2rem;
}

/* Media queries */

@media only screen and (max-width: 550px) {
  .section-text {
    word-spacing: 1px;
    letter-spacing: 1px;
  }
}

@media only screen and (max-width: 424px) {
  .section-text {
    word-spacing: 1px;
    letter-spacing: 1px;
  }
}